import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { UPDATE_REMOTE_ASSIST_CUSTOMER_MUTATION } from 'api';
import {
  DisplayEquipmentFields,
  EditableEquipmentActions,
  EditableEquipmentFields,
  ErrorMessage,
  Unknown,
} from 'components';
import { isEqual } from 'lodash';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import {
  Equipment,
  UpdateRemoteAssistCustomerMutation,
  UpdateRemoteAssistCustomerMutationVariables,
} from 'types';

type EquipmentAccordionDisplayProps = {
  equipment?: Equipment[] | undefined | null;
  paddingIfNoEquipment?: string; // padding around the 'n/a' text needs to vary depending on where this component is placed
  editEquipment?: boolean;
  setEditEquipment?: Dispatch<SetStateAction<boolean>>;
  remoteAssistId?: string;
  refetchRemoteAssist?: () => Promise<unknown>;
};

export const EquipmentAccordionDisplay = ({
  equipment,
  paddingIfNoEquipment = '',
  editEquipment,
  setEditEquipment,
  remoteAssistId,
  refetchRemoteAssist,
}: EquipmentAccordionDisplayProps) => {
  const hasEquipment = equipment && equipment.length > 0;

  const emptyEquipmentValue = {
    name: '',
    type: '',
    brand: '',
    modelNumber: '',
    errorCode: '',
    serialNumber: '',
    equipmentNotes: '',
  };

  const currentEquipmentValues: Equipment[] | undefined = useMemo(() => {
    return equipment?.map((equip) => ({
      name: equip?.name ?? null,
      type: equip?.type ?? null,
      brand: equip?.brand ?? null,
      modelNumber: equip?.modelNumber ?? null,
      errorCode: equip.errorCode ?? null,
      serialNumber: equip.serialNumber ?? null,
      equipmentNotes: equip.equipmentNotes ?? null,
    }));
  }, [equipment]);

  const [equipmentUpdateInput, setEquipmentUpdateInput] = useState<
    Equipment[] | undefined
  >(currentEquipmentValues);
  const [loadingEquipment, setLoadingEquipment] = useState<boolean>(false);
  const [equipmentCountChanged, setEquipmentCountChanged] =
    useState<boolean>(false);
  const [errorMessageUpdateEquipment, setErrorMessageUpdateEquipment] =
    useState<string>('');

  const [updateEquipmentDetails] = useMutation<
    UpdateRemoteAssistCustomerMutation,
    UpdateRemoteAssistCustomerMutationVariables
  >(UPDATE_REMOTE_ASSIST_CUSTOMER_MUTATION);

  const equipmentUpdateMatchesCurrent = useMemo(() => {
    return isEqual(equipmentUpdateInput, currentEquipmentValues);
  }, [equipmentUpdateInput, currentEquipmentValues]);

  const disableSave = useMemo(
    () =>
      (equipmentUpdateMatchesCurrent && !equipmentCountChanged) ||
      loadingEquipment,
    [equipmentUpdateMatchesCurrent, equipmentCountChanged, loadingEquipment],
  );

  const onEquipmentChange =
    (variableName: keyof Equipment, index: number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const newEquipmentUpdateInput = equipmentUpdateInput?.map(
        (equipment, i) => {
          if (i !== index) {
            return equipment;
          }
          return {
            ...equipment,
            [variableName]:
              event.target.value.trim() === '' ? null : event.target.value,
          };
        },
      );
      setEquipmentUpdateInput(newEquipmentUpdateInput);
    };

  const cancelEquipment = () => {
    if (!setEditEquipment) {
      return;
    }
    setEditEquipment(false);
    setErrorMessageUpdateEquipment('');
    setEquipmentUpdateInput(currentEquipmentValues);
  };

  const saveEquipment = async () => {
    if (!refetchRemoteAssist || !setEditEquipment) {
      return;
    }
    try {
      setLoadingEquipment(true);
      await updateEquipmentDetails({
        variables: {
          id: remoteAssistId ?? '',
          remoteAssistUpdateInput: {
            diagnosis: {
              equipment: equipmentUpdateInput,
            },
          },
        },
      });
      await refetchRemoteAssist();
      setEditEquipment(false);
      setErrorMessageUpdateEquipment('');
    } catch (error) {
      setErrorMessageUpdateEquipment('Unable to update session details');
    } finally {
      setLoadingEquipment(false);
      setEquipmentCountChanged(false);
    }
  };

  const addEquipment = () => {
    const newEquipment = [...(equipmentUpdateInput ?? [])];
    newEquipment?.push(emptyEquipmentValue);
    setEquipmentUpdateInput(newEquipment);
    setEquipmentCountChanged(true);
  };

  const deleteEquipment = (index: number) => {
    const newEquipment = [...(equipmentUpdateInput ?? [])];
    newEquipment?.splice(index, 1);
    setEquipmentUpdateInput(newEquipment);
    setEquipmentCountChanged(true);
  };

  if (!hasEquipment && !editEquipment) {
    return (
      <Grid
        sx={{
          padding: paddingIfNoEquipment,
        }}
      >
        <Unknown />
      </Grid>
    );
  }

  return (
    <>
      {editEquipment && errorMessageUpdateEquipment && (
        <Grid pb={2}>
          <ErrorMessage
            message={errorMessageUpdateEquipment}
            variant="subtitle1"
            iconSize={18}
          />
        </Grid>
      )}

      {editEquipment &&
        equipmentUpdateInput &&
        equipmentUpdateInput?.map((equip, index) => (
          <EditableEquipmentFields
            equip={equip}
            index={index}
            onEquipmentChange={onEquipmentChange}
            loadingEquipment={loadingEquipment}
            deleteEquipment={deleteEquipment}
          />
        ))}

      {!editEquipment &&
        equipment &&
        equipment.map((equip) => <DisplayEquipmentFields equip={equip} />)}

      {editEquipment && setEditEquipment && (
        <EditableEquipmentActions
          loadingEquipment={loadingEquipment}
          addEquipment={addEquipment}
          saveEquipment={saveEquipment}
          cancelEquipment={cancelEquipment}
          disableSave={disableSave}
        />
      )}
    </>
  );
};
