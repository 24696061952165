import { gql } from '@apollo/client';

export const SERVICE_CODES_QUERY = gql`
  query serviceCodes {
    serviceCodes {
      data {
        id
        name
      }
    }
  }
`;
