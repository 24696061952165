import { gql } from '@apollo/client';
import { childrenRemoteAssistProps, parentRemoteAssistProps } from 'api';

export const REMOTE_ASSIST_FOR_PAST_RA_QUERY = gql`
  query remoteAssistForPastRA($id: ID!) {
    remoteAssist(id: $id) {
      id
      reference
      supplyClientId
      affiliateKey
      demandClientId
      tradeId
      serviceCodeId
      type
      status
      customer {
        reference
        email {
          address
        }
        firstName
        lastName
        phone {
          number
        }
        postalCode
        notes
        isMember
      }
      diagnosis {
        id
        equipment {
          name
          type
          brand
          reference
          modelNumber
          serialNumber
          errorCode
          equipmentNotes
        }
      }
      technician {
        id
        notes
      }
      assignedAt
      lastStatusChangeAt
      recordingURLs
      createdAt
      scheduled {
        promisedWindow {
          start
          end
        }
      }
      invoice {
        productType
        productOutcome
        customerPrice
        billableVideoMinutes
      }
      parent {
        ...parentRemoteAssistProps
        children {
          ...childrenRemoteAssistProps
        }
      }
      children {
        ...childrenRemoteAssistProps
      }
      canceledReason
      parentRelation
    }
  }
  ${childrenRemoteAssistProps}
  ${parentRemoteAssistProps}
`;
