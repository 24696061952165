import { useEffect, useState } from 'react';

// returns the current list of all media devices
export function useDevices() {
  const [devices, setDevices] = useState<MediaDeviceInfo[] | null>(null);

  useEffect(() => {
    const getDevices = () =>
      navigator.mediaDevices
        .enumerateDevices()
        .then((mediaDevices) =>
          mediaDevices.every((device) => !(device.deviceId && device.label)),
        )
        .then((shouldAskForMediaPermissions) => {
          if (shouldAskForMediaPermissions) {
            // ask for media permissions if needed
            return navigator.mediaDevices.getUserMedia({
              audio: true,
              video: true,
            });
          }
        })
        .then(() =>
          navigator.mediaDevices
            .enumerateDevices()
            .then((mediaDevices) => setDevices(mediaDevices)),
        );

    navigator.mediaDevices.addEventListener('devicechange', getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    };
  }, []);

  return devices;
}
