let access_token = '';

export enum UserRole {
  Admin = 'Admin',
  Agent = 'Agent',
  Technician = 'Technician',
}

export const setAuthToken = (token: string) => {
  access_token = token;
};

export const getToken = () => {
  return access_token;
};
