import {
  DeveloperApiClient,
  getManualAuthDeveloperApiClient,
} from '@hpx-it/developer-api-client';
import { UserContext } from 'contexts/UserContext';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

type DeveloperApiClientContextProps = {
  getDeveloperApiClient: () => DeveloperApiClient;
};

export type DeveloperApiClientProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: DeveloperApiClientContextProps = {
  getDeveloperApiClient: () =>
    getManualAuthDeveloperApiClient(
      process.env.REACT_APP_DEVELOPER_API_URL,
      '',
    ),
};

export const throwDeveloperApiUnauthenticatedError = (): never => {
  throw new Error('Unauthenticated to make request');
};

export const DeveloperApiClientContext =
  createContext<DeveloperApiClientContextProps>(DEFAULT_CONTEXT);

export const DeveloperApiClientProvider = ({
  children,
}: DeveloperApiClientProviderProps) => {
  const { token } = useContext(UserContext);

  const developerApiClient = useMemo(() => {
    if (!token) {
      return undefined;
    }

    return getManualAuthDeveloperApiClient(
      process.env.REACT_APP_DEVELOPER_API_URL,
      token,
    );
  }, [token]);

  const getDeveloperApiClient = useCallback((): DeveloperApiClient => {
    if (!developerApiClient) {
      throwDeveloperApiUnauthenticatedError();
    }

    return developerApiClient as DeveloperApiClient;
  }, [developerApiClient]);

  return (
    <DeveloperApiClientContext.Provider
      value={{
        getDeveloperApiClient,
      }}
    >
      {children}
    </DeveloperApiClientContext.Provider>
  );
};
