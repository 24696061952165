import { DeveloperApi } from '@hpx-it/developer-api-types';
import { Theme } from '@mui/material';
import { AttachmentApiContext, ClientApiContext } from 'api';
import { UserContext } from 'contexts';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DEFAULT_PRIMARY_COLOR, generateTheme } from 'utils';

type ClientBranding = {
  clientId?: string;
  palette?: DeveloperApi.Clients.Palette;
  assetIds?: DeveloperApi.Clients.BrandingAssets;
  assetUrls?: {
    [asset in DeveloperApi.Clients.BrandingAssetType]?: string;
  };
};

type ClientContextProps = {
  branding?: ClientBranding;
  setBranding: Dispatch<SetStateAction<ClientBranding | undefined>>;
  isLoadingBrand: boolean;
};

export type ClientProviderProps = {
  setTheme: Dispatch<SetStateAction<Theme>>;
  children: ReactNode;
};

const DEFAULT_CONTEXT: ClientContextProps = {
  branding: {},
  setBranding: () => undefined,
  isLoadingBrand: false,
};

export const ClientContext = createContext<ClientContextProps>(DEFAULT_CONTEXT);

export const ClientProvider = ({ setTheme, children }: ClientProviderProps) => {
  const { getClient } = useContext(ClientApiContext);
  const { downloadAttachment } = useContext(AttachmentApiContext);
  const { userClientId } = useContext(UserContext);

  const [client, setClient] = useState<DeveloperApi.Clients.BaseClient>();
  const [branding, setBranding] = useState<ClientBranding>();
  const [isLoadingBrand, setIsLoadingBrand] = useState<boolean>(false);
  const [isLoadingClient, setIsLoadingClient] = useState<boolean>(false);

  useEffect(() => {
    const primaryColor = branding?.palette?.primary
      ? branding.palette.primary
      : DEFAULT_PRIMARY_COLOR;

    setTheme(generateTheme(primaryColor));
  }, [branding?.palette?.primary, setTheme]);

  useMemo(async () => {
    if (client?.id === userClientId) {
      return;
    }

    setIsLoadingClient(true);
    const clientData = await getClient();
    setIsLoadingClient(false);
    setClient(clientData);
  }, [client?.id, userClientId, getClient]);

  useMemo(async () => {
    if (client?.branding && branding?.clientId !== client.id) {
      try {
        setIsLoadingBrand(true);

        const brandingObject = new DeveloperApi.Clients.Branding(
          client.branding,
        );

        const paletteObject: DeveloperApi.Clients.Palette = {};
        DeveloperApi.Clients.VALID_PALETTE_COLORS.forEach(
          (color) =>
            (paletteObject[color] = brandingObject.getPaletteColor(color)),
        );

        const assetsIdsObject: DeveloperApi.Clients.BrandingAssets = {};
        DeveloperApi.Clients.VALID_ASSET_TYPES.forEach(
          (asset) =>
            (assetsIdsObject[asset] = brandingObject.getAttachmentId(asset)),
        );

        const assetsUrlsObject: {
          [asset in DeveloperApi.Clients.BrandingAssetType]?: string;
        } = {};
        for (const [asset, assetId] of Object.entries(assetsIdsObject)) {
          if (assetId) {
            const url = URL.createObjectURL(
              await downloadAttachment({ id: assetId }),
            );
            if (url) {
              assetsUrlsObject[
                asset as keyof DeveloperApi.Clients.BrandingAssets
              ] = url;
            }
          }
        }

        setBranding((prevBranding) => {
          return {
            ...prevBranding,
            clientId: client.id,
            palette: paletteObject,
            assetIds: assetsIdsObject,
            assetUrls: assetsUrlsObject,
          };
        });
      } finally {
        setIsLoadingBrand(false);
      }
    }
  }, [branding?.clientId, client?.branding, client?.id, downloadAttachment]);

  return (
    <ClientContext.Provider
      value={{
        branding,
        setBranding,
        isLoadingBrand: isLoadingBrand || isLoadingClient,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
