import { lazy } from 'react';
import { View } from 'utils';

export const views: View[] = [
  {
    id: 'admin-portal',
    sidebar: {
      name: 'Admin Portal',
      icon: lazy(() => import('@mui/icons-material/AdminPanelSettings')),
    },
    path: '/admin-portal',
    component: lazy(() => import('./component')),
  },
];
