import { useMutation } from '@apollo/client';
import { ContextHolder } from '@frontegg/rest-api';
import { logger } from '@hpx-it/react-app';
import { Close } from '@mui/icons-material';
import UserIcon from '@mui/icons-material/PersonOutline';
import {
  AppBar,
  Box,
  Dialog,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { UPDATE_TECHNICIAN_STATUS_MUTATION } from 'api';
import { DevicesContext, UserContext, ViewsContext } from 'contexts';
import { startCase } from 'lodash';
import { MouseEvent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  TechnicianQuery,
  TechnicianStatus,
  UpdateTechnicianStatusMutation,
  UpdateTechnicianStatusMutationVariables,
} from 'types';
import {
  CLIENT_ID_TO_NAME_MAP,
  STATUS_TYPES,
  TOP_NAV_HEIGHT,
  UserRole,
  pushToastMessage,
  technicianStatusColor,
} from 'utils';

const { REACT_APP_BUILD_TIME, REACT_APP_COMMIT_REF } = process.env;

type TopNavigationProps = {
  technician: TechnicianQuery['technician'];
};

export const TopNavigation = ({ technician }: TopNavigationProps) => {
  const theme = useTheme();
  const {
    userName,
    userClientId,
    userPossibleClientIds,
    switchClient,
    userRoles,
  } = useContext(UserContext);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [showAbout, setShowAbout] = useState<boolean>(false);
  const { currentView } = useContext(ViewsContext);

  const { setShowConfigure } = useContext(DevicesContext);

  const [updateTechnicianStatus, { loading }] = useMutation<
    UpdateTechnicianStatusMutation,
    UpdateTechnicianStatusMutationVariables
  >(UPDATE_TECHNICIAN_STATUS_MUTATION);

  const statusChangeDisabled = loading;

  const logout = () => {
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}`;
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <AppBar position="relative">
        <Grid
          container
          height={TOP_NAV_HEIGHT}
          alignItems="center"
          padding="0 1rem"
          direction="row"
        >
          <Grid item container xs alignItems="center" spacing={2}>
            <Grid item>
              <Typography
                variant="h4"
                fontWeight={500}
                sx={{ '> a': { color: 'inherit', textDecoration: 'inherit' } }}
                data-test-id="top-navigation-title"
              >
                {currentView && (
                  <Link to={currentView.path}>
                    {currentView.heading ??
                      currentView.sidebar?.name ??
                      'Field Guide'}
                  </Link>
                )}
              </Typography>
            </Grid>
            {userPossibleClientIds.length > 1 && (
              <Grid item>
                <Select
                  value={userClientId}
                  onChange={(event) => {
                    switchClient(event.target.value);
                  }}
                  size="small"
                  sx={{ backgroundColor: 'white' }}
                >
                  {userPossibleClientIds.map((clientId) => (
                    <MenuItem value={clientId} key={clientId}>
                      {CLIENT_ID_TO_NAME_MAP[clientId] ?? 'Oops'}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            direction="row"
            width="auto"
            flexWrap="nowrap"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                data-test-id="user-icon"
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  setDropdownAnchorEl(event.currentTarget);
                }}
              >
                <Box
                  p={1}
                  bgcolor={theme.palette.grey[100]}
                  borderRadius="1.5rem"
                  border={`1px solid ${theme.palette.primary.light}`}
                  sx={{
                    ':hover': { backgroundColor: theme.palette.grey[200] },
                  }}
                >
                  <UserIcon sx={{ width: 30, height: 25 }} />
                  {technician && (
                    <Box
                      position="absolute"
                      bgcolor={technicianStatusColor(technician.status)}
                      p="0.35rem"
                      borderRadius="1rem"
                      border={`3px solid ${theme.palette.primary.main}`}
                      mt="calc(-12.5px + 0.35rem)"
                      ml="calc(15px + 0.35rem)"
                    />
                  )}
                </Box>
              </IconButton>
              <Menu
                anchorEl={dropdownAnchorEl}
                open={!!dropdownAnchorEl}
                onClose={() => setDropdownAnchorEl(null)}
                sx={{
                  '.MuiMenu-paper': {
                    left: 'auto !important',
                    right: '0',
                    minWidth: '12em',
                    backgroundColor: theme.palette.grey[100],
                  },
                }}
                MenuListProps={{
                  sx: {
                    '.Mui-disabled': {
                      opacity: '1 !important',
                      color: theme.palette.grey[400],
                    },
                  },
                }}
              >
                {technician && (
                  <>
                    <Box pt={1} pl={2} pr={2}>
                      <Typography variant="body2" fontWeight={900}>
                        Set your status
                      </Typography>
                    </Box>
                    {STATUS_TYPES.map((status: TechnicianStatus, i: number) => {
                      const currentStatus = status === technician.status;
                      return (
                        <MenuItem
                          data-test-id={`status-menu-item-${i}`}
                          key={i}
                          disabled={currentStatus || statusChangeDisabled}
                          divider={i === STATUS_TYPES.length - 1}
                          onClick={() => {
                            const update = async () => {
                              try {
                                await updateTechnicianStatus({
                                  variables: { status },
                                });
                              } catch (error) {
                                logger.error(
                                  'Failed to update technician status',
                                  { message: error },
                                );
                                pushToastMessage({
                                  content: 'Unable to update status',
                                  variant: 'error',
                                  action: {
                                    text: 'retry',
                                    function: update,
                                  },
                                });
                              }
                            };
                            update();
                            setDropdownAnchorEl(null);
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={currentStatus ? '900' : 'inherit'}
                            color={
                              currentStatus && !statusChangeDisabled
                                ? theme.palette.primary.main
                                : 'auto'
                            }
                          >
                            {startCase(status.toLowerCase())}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </>
                )}

                {userRoles.includes(UserRole.Technician) && (
                  <MenuItem
                    onClick={() => {
                      setShowConfigure(true);
                    }}
                  >
                    <Typography variant="body2">Configure Video</Typography>
                  </MenuItem>
                )}

                <MenuItem
                  divider
                  onClick={() => {
                    setShowAbout(true);
                  }}
                >
                  <Typography variant="body2">About</Typography>
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    if (technician) {
                      await updateTechnicianStatus({
                        variables: { status: TechnicianStatus.OFFLINE },
                      });
                    }
                    logout();
                  }}
                >
                  <Typography variant="body2">Logout</Typography>
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <Typography
                  data-test-id="top-navigation-user-name"
                  variant="subtitle2"
                  color={theme.palette.common.white}
                  lineHeight={1}
                  fontWeight={500}
                >
                  {userName}
                </Typography>
              </Grid>
              {technician && (
                <Grid item>
                  <Typography
                    data-test-id="technician-status"
                    variant="caption"
                    color={theme.palette.common.white}
                    lineHeight={1}
                  >
                    {startCase(technician.status.toLowerCase())}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
      {showAbout && (
        <Dialog open onClose={() => setShowAbout(false)}>
          <Grid container p={3} direction="column" spacing={1}>
            <Grid item container alignContent="flex-end">
              <Grid item xs>
                <Typography variant="h6">Fieldguide</Typography>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <IconButton
                    color="inherit"
                    onClick={() => setShowAbout(false)}
                  >
                    <Close fontSize="medium" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            {REACT_APP_COMMIT_REF && (
              <Grid item>
                <Typography
                  variant="body1"
                  display="inline"
                >{`Version: `}</Typography>
                <Typography variant="body1" fontWeight="light" display="inline">
                  {REACT_APP_COMMIT_REF}
                </Typography>
              </Grid>
            )}
            {REACT_APP_BUILD_TIME && (
              <Grid item>
                <Typography
                  variant="body1"
                  display="inline"
                >{`From: `}</Typography>
                <Typography variant="body1" fontWeight="light" display="inline">
                  {`${new Intl.DateTimeFormat('GMT', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZoneName: 'short',
                  }).format(new Date(REACT_APP_BUILD_TIME))}`}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Dialog>
      )}
    </Box>
  );
};
