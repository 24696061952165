import {
  DeveloperApiClient,
  DeveloperApiClientTypes,
} from '@hpx-it/developer-api-client';
import { DeveloperApi } from '@hpx-it/developer-api-types';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type RemoteAssistApiContextProps = {
  getRemoteAssists: DeveloperApiClient['getRemoteAssists'];
  getRemoteAssist: DeveloperApiClient['getRemoteAssist'];
  cancelRemoteAssist: DeveloperApiClient['cancelRemoteAssist'];
};

export type RemoteAssistApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: RemoteAssistApiContextProps = {
  getRemoteAssists: () => throwDeveloperApiUnauthenticatedError(),
  getRemoteAssist: () => throwDeveloperApiUnauthenticatedError(),
  cancelRemoteAssist: () => throwDeveloperApiUnauthenticatedError(),
};

export const RemoteAssistApiContext =
  createContext<RemoteAssistApiContextProps>(DEFAULT_CONTEXT);

export const RemoteAssistApiProvider = ({
  children,
}: RemoteAssistApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getRemoteAssists = useCallback(
    async (input: DeveloperApi.RemoteAssists.GetRemoteAssistsInput) =>
      await getDeveloperApiClient().getRemoteAssists(input),
    [getDeveloperApiClient],
  );

  const getRemoteAssist = useCallback(
    async (input: DeveloperApiClientTypes.GetRemoteAssistInput) =>
      await getDeveloperApiClient().getRemoteAssist(input),
    [getDeveloperApiClient],
  );

  const cancelRemoteAssist = useCallback(
    async (input: DeveloperApiClientTypes.CancelRemoteAssistInput) =>
      await getDeveloperApiClient().cancelRemoteAssist(input),
    [getDeveloperApiClient],
  );

  return (
    <RemoteAssistApiContext.Provider
      value={{ getRemoteAssists, getRemoteAssist, cancelRemoteAssist }}
    >
      {children}
    </RemoteAssistApiContext.Provider>
  );
};
