import { lazy } from 'react';
import { UserRole, View } from 'utils';

export const views: View[] = [
  {
    id: 'technician-queue-dashboard',
    sidebar: {
      name: 'Queue',
      icon: lazy(() => import('@mui/icons-material/ReceiptLong')),
    },
    checker: ({ userRoles }) => {
      return userRoles.some((role) =>
        [UserRole.Technician, UserRole.Agent, UserRole.Admin].includes(role),
      );
    },
    path: '/technician-queue-dashboard',
    component: lazy(() => import('./component')),
  },
];
