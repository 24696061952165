import {
  Box,
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { Suspense, lazy } from 'react';

export const LeftNavigationListItem = ({
  buttonText,
  onClick,
  border,
  selected,
  isLHSMenuOpen,
  Icon,
}: {
  buttonText: string;
  onClick: () => void;
  border: boolean;
  selected: boolean;
  isLHSMenuOpen: boolean;
  Icon: ReturnType<typeof lazy>;
}) => {
  const theme = useTheme();
  const buttonColor = selected
    ? theme.palette.primary.light
    : theme.palette.grey[600];

  return (
    <Box
      borderBottom={border ? `1px solid ${theme.palette.grey[200]}` : ''}
      padding={isLHSMenuOpen ? '0.25rem 0' : ''}
    >
      <ListItem
        sx={{
          justifyContent: 'center',
          padding: isLHSMenuOpen ? '0.25rem' : '0.5rem',
          transition: 'all 0.75s',
          textDecoration: 'none',
          color: 'black',
        }}
        onClick={onClick}
      >
        <Tooltip
          title={isLHSMenuOpen ? '' : buttonText}
          placement="right"
          arrow
        >
          <ListItemButton
            sx={{
              alignContent: 'left',
              width: isLHSMenuOpen ? '14.25rem' : '3.5rem',
              height: '2rem',
              borderRadius: '2rem',
              backgroundColor: (theme) =>
                selected
                  ? alpha(theme.palette.primary.main, 0.2)
                  : theme.palette.common.white,
              buttonRipple: theme.palette.common.white,
              transition: 'all 0.75s',
            }}
          >
            <ListItemIcon
              sx={{
                position: 'relative',
                right: '1rem',
                justifyContent: 'center',
              }}
            >
              <Suspense
                fallback={<CircularProgress size={24} color="secondary" />}
              >
                <Icon color={buttonColor} sx={{ color: buttonColor }} />
              </Suspense>
            </ListItemIcon>

            {isLHSMenuOpen && (
              <Typography
                variant="body2"
                position="relative"
                right="1rem"
                justifyContent="left"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {buttonText}
              </Typography>
            )}
          </ListItemButton>
        </Tooltip>
      </ListItem>
    </Box>
  );
};
