import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import {
  ClientContext,
  DevicesContext,
  UserContext,
  VideoBackgroundId,
} from 'contexts';
import { ChangeEvent, useContext } from 'react';
import { CLIENT_ID_TO_CLIENT_MAP } from 'utils';

type ConfigureModalProps = {
  onClose: () => void;
};

export const ConfigureModal = ({ onClose }: ConfigureModalProps) => {
  const { branding } = useContext(ClientContext);
  const { userClientId } = useContext(UserContext);
  const { videoBackgroundId, setVideoBackgroundId } =
    useContext(DevicesContext);

  return (
    <Dialog open onClose={onClose} maxWidth="xl">
      <DialogTitle>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h4">Configure Video</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon width={16} height={16} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography width="100%" marginBottom="10px">
            Please preview your video prior to joining your next call.
          </Typography>

          <FormControl fullWidth>
            <TextField
              label="Video background"
              select
              variant="filled"
              value={videoBackgroundId}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setVideoBackgroundId(event.target.value as VideoBackgroundId)
              }
            >
              <MenuItem value="none" key="none">
                None
              </MenuItem>
              <MenuItem value="blur" key="blur">
                Blur
              </MenuItem>
              <MenuItem value="pathlight" key="pathlight">
                Pathlight
              </MenuItem>
              {branding?.assetUrls?.video_background && (
                <MenuItem value="client" key="client">
                  {CLIENT_ID_TO_CLIENT_MAP[userClientId].name}
                </MenuItem>
              )}
            </TextField>
          </FormControl>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
