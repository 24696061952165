import { lazy } from 'react';
import { View } from 'utils';

export const views: View[] = [
  {
    id: 'reporting',
    sidebar: {
      name: 'Reporting',
      icon: lazy(() => import('@mui/icons-material/AutoGraph')),
    },
    checker: () => {
      // backend undeployed
      return false;
    },
    path: '/reporting',
    component: lazy(() => import('./component')),
  },
];
