import {
  DeveloperApiClient,
  DeveloperApiClientTypes,
} from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type VideoCallingApiContextProps = {
  getTechnicianToken: DeveloperApiClient['getTechnicianToken'];
  getRecordingURLs: DeveloperApiClient['getRecordingURLs'];
};

export type VideoCallingApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: VideoCallingApiContextProps = {
  getTechnicianToken: () => throwDeveloperApiUnauthenticatedError(),
  getRecordingURLs: () => throwDeveloperApiUnauthenticatedError(),
};

export const VideoCallingApiContext =
  createContext<VideoCallingApiContextProps>(DEFAULT_CONTEXT);

export const VideoCallingApiProvider = ({
  children,
}: VideoCallingApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getTechnicianToken = useCallback(
    async (input: DeveloperApiClientTypes.GetTechnicianTokenInput) =>
      await getDeveloperApiClient().getTechnicianToken(input),
    [getDeveloperApiClient],
  );

  const getRecordingURLs = useCallback(
    async (input: DeveloperApiClientTypes.GetRecordingURLsInput) =>
      await getDeveloperApiClient().getRecordingURLs(input),
    [getDeveloperApiClient],
  );

  return (
    <VideoCallingApiContext.Provider
      value={{
        getTechnicianToken,
        getRecordingURLs,
      }}
    >
      {children}
    </VideoCallingApiContext.Provider>
  );
};
