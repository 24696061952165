import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

type DeleteEquipmentModalProps = {
  showModal: boolean;
  handleDeleteEquipment: () => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const DeleteEquipmentModal = ({
  showModal,
  handleDeleteEquipment,
  setShowModal,
}: DeleteEquipmentModalProps) => {
  const theme = useTheme();

  return (
    <Dialog
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      PaperProps={{
        style: { borderRadius: '0.5rem' },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        padding="0.5rem 0.5rem 0.5rem 1.2rem"
        sx={{ bgcolor: theme.palette.primary.main }}
      >
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography color={theme.palette.common.white} variant="h6">
              Confirm Deletion
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setShowModal(false);
              }}
            >
              <CloseIcon
                sx={{
                  color: theme.palette.common.white,
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Grid padding="1.25rem">
        <Typography>
          Are you sure you want to permanently delete this equipment?
        </Typography>
      </Grid>
      <Grid
        item
        container
        spacing={1}
        display="flex"
        flexDirection="row-reverse"
        padding="1rem"
      >
        <Grid item>
          <Button
            variant="contained"
            sx={{
              borderRadius: '100px',
              backgroundColor: '#10857A',
            }}
            onClick={() => {
              handleDeleteEquipment();
            }}
          >
            Delete Equipment
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            sx={{
              borderRadius: '100px',
              borderColor: '#10857A',
              color: '#10857A',
            }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            Keep It
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
