import { useDevices, useLocalStorage } from 'hooks';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type VideoBackgroundId = 'none' | 'blur' | 'pathlight' | 'client';

type DevicesContextProps = {
  audioInputDeviceId: string;
  setAudioInputDeviceId: (id: string) => void;
  audioOutputDeviceId: string;
  setAudioOutputDeviceId: (id: string) => void;
  ringerAudioOutputDeviceId: string;
  setRingerAudioOutputDeviceId: (id: string) => void;
  videoInputDeviceId: string;
  setVideoInputDeviceId: (id: string) => void;
  videoBackgroundId: VideoBackgroundId;
  setVideoBackgroundId: (id: VideoBackgroundId) => void;
  getAudio: (name: string, src: string, ringer?: boolean) => HTMLAudioElement;
  showConfigure: boolean;
  setShowConfigure: (show: boolean) => void;
};

type CustomHTMLAudioElement = HTMLAudioElement & {
  setSinkId?: (sinkId: string) => Promise<void>;
  sinkId?: string;
};

type DevicesProviderProps = {
  children: React.ReactNode;
};

const DEFAULT_CONTEXT: DevicesContextProps = {
  audioInputDeviceId: '',
  setAudioInputDeviceId: () => {},
  audioOutputDeviceId: '',
  setAudioOutputDeviceId: () => {},
  ringerAudioOutputDeviceId: '',
  setRingerAudioOutputDeviceId: () => {},
  videoInputDeviceId: '',
  setVideoInputDeviceId: () => {},
  videoBackgroundId: 'pathlight',
  setVideoBackgroundId: () => {},
  getAudio: (src: string) => {
    return new Audio(src);
  },
  showConfigure: false,
  setShowConfigure: () => {},
};

export const DevicesContext =
  createContext<DevicesContextProps>(DEFAULT_CONTEXT);

function checkAndSetDevice(
  devices: MediaDeviceInfo[] | null,
  deviceKind: MediaDeviceKind,
  currentDeviceId: string,
  preferredDeviceId: string,
  setDeviceId: Dispatch<SetStateAction<string>>,
) {
  if (devices) {
    const devicesOfDesiredKind = devices.filter(
      (device) => device.kind === deviceKind,
    );
    // if the device is not the preferred device and the preferred device is available, change device to preferred device
    if (
      currentDeviceId !== preferredDeviceId &&
      devicesOfDesiredKind.find(
        (device) => device.deviceId === preferredDeviceId,
      )
    ) {
      setDeviceId(preferredDeviceId);
      // else if there isn't a device or the current device isn't available, switch to the default device
    } else if (
      !currentDeviceId ||
      !devicesOfDesiredKind.find(
        (device) => device.deviceId === currentDeviceId,
      )
    ) {
      setDeviceId(
        devicesOfDesiredKind.length > 0 ? devicesOfDesiredKind[0].deviceId : '',
      );
    }
  }
}

export const DevicesProvider = ({ children }: DevicesProviderProps) => {
  // preferred device id indicates the device to use if available
  const [preferredAudioInputDeviceId, setPreferredAudioInputDeviceId] =
    useLocalStorage('audioInputDeviceId', '');
  // device id indicates the device currently being used
  const [audioInputDeviceId, setAudioInputDeviceId] = useState('');
  const [preferredAudioOutputDeviceId, setPreferredAudioOutputDeviceId] =
    useLocalStorage('audioOutputDeviceId', '');
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState('');
  const [
    preferredRingerAudioOutputDeviceId,
    setPreferredRingerAudioOutputDeviceId,
  ] = useLocalStorage('ringerAudioOutputDeviceId', '');
  const [ringerAudioOutputDeviceId, setRingerAudioOutputDeviceId] =
    useState('');
  const [preferredVideoInputDeviceId, setPreferredVideoInputDeviceId] =
    useLocalStorage('videoInputDeviceId', '');
  const [videoInputDeviceId, setVideoInputDeviceId] = useState('');
  const [videoBackgroundId, setVideoBackgroundId] =
    useLocalStorage<VideoBackgroundId>('videoBackgroundId', 'pathlight');
  const [showConfigure, setShowConfigure] = useState(false);
  const audioElements = useMemo<Record<string, CustomHTMLAudioElement>>(
    () => ({}),
    [],
  );
  const ringerAudioElements = useMemo<Record<string, CustomHTMLAudioElement>>(
    () => ({}),
    [],
  );
  const devices = useDevices();

  useEffect(() => {
    checkAndSetDevice(
      devices,
      'audioinput',
      audioInputDeviceId,
      preferredAudioInputDeviceId,
      setAudioInputDeviceId,
    );
  }, [audioInputDeviceId, devices, preferredAudioInputDeviceId]);

  useEffect(() => {
    checkAndSetDevice(
      devices,
      'audiooutput',
      audioOutputDeviceId,
      preferredAudioOutputDeviceId,
      setAudioOutputDeviceId,
    );
  }, [audioOutputDeviceId, devices, preferredAudioOutputDeviceId]);

  useEffect(() => {
    checkAndSetDevice(
      devices,
      'audiooutput',
      ringerAudioOutputDeviceId,
      preferredRingerAudioOutputDeviceId,
      setRingerAudioOutputDeviceId,
    );
  }, [ringerAudioOutputDeviceId, devices, preferredRingerAudioOutputDeviceId]);

  useEffect(() => {
    checkAndSetDevice(
      devices,
      'videoinput',
      videoInputDeviceId,
      preferredVideoInputDeviceId,
      setVideoInputDeviceId,
    );
  }, [videoInputDeviceId, devices, preferredVideoInputDeviceId]);

  // set registered audio elements to output to the selected audio device
  useEffect(() => {
    Object.values(audioElements).forEach((audioElement) =>
      audioElement.setSinkId?.(audioOutputDeviceId),
    );
  }, [audioElements, audioOutputDeviceId]);

  useEffect(() => {
    Object.values(ringerAudioElements).forEach((audioElement) =>
      audioElement.setSinkId?.(ringerAudioOutputDeviceId),
    );
  }, [ringerAudioElements, ringerAudioOutputDeviceId]);

  /**
   * Returns an audio element whose audio output will always match the selected device
   */
  const getAudio = useCallback(
    (name: string, src: string, ringer?: boolean) => {
      const elements = ringer ? ringerAudioElements : audioElements;
      if (!elements[name]) {
        const audio: CustomHTMLAudioElement = new Audio(src);
        audio.setSinkId?.(
          ringer ? ringerAudioOutputDeviceId : audioOutputDeviceId,
        );
        elements[name] = audio;
      }
      return elements[name];
    },
    [
      audioElements,
      audioOutputDeviceId,
      ringerAudioElements,
      ringerAudioOutputDeviceId,
    ],
  );

  return (
    <DevicesContext.Provider
      value={{
        audioInputDeviceId,
        setAudioInputDeviceId: (id) => {
          setAudioInputDeviceId(id);
          setPreferredAudioInputDeviceId(id);
        },
        audioOutputDeviceId,
        setAudioOutputDeviceId: (id) => {
          setAudioOutputDeviceId(id);
          setPreferredAudioOutputDeviceId(id);
        },
        ringerAudioOutputDeviceId,
        setRingerAudioOutputDeviceId: (id) => {
          setRingerAudioOutputDeviceId(id);
          setPreferredRingerAudioOutputDeviceId(id);
        },
        videoInputDeviceId,
        setVideoInputDeviceId: (id) => {
          setVideoInputDeviceId(id);
          setPreferredVideoInputDeviceId(id);
        },
        videoBackgroundId,
        setVideoBackgroundId,
        getAudio,
        showConfigure,
        setShowConfigure,
      }}
    >
      {children}
    </DevicesContext.Provider>
  );
};
