import { gql } from '@apollo/client';

export const REMOTE_ASSISTS_QUERY = gql`
  query remoteAssists($input: RemoteAssistsInput!) {
    remoteAssists(input: $input) {
      data {
        id
        reference
        affiliateKey
        canceledReason
        clientId
        customer {
          email {
            address
          }
          firstName
          lastName
          phone {
            number
          }
          postalCode
          notes
        }
        demandClientId
        tradeId
        type
        status
        technician {
          id
        }
        createdAt
        assignedAt
        lastStatusChangeAt
        scheduled {
          promisedWindow {
            start
            end
          }
        }
        eligibleSupply {
          technicianIds
        }
        portalStatus
        reviewed
      }
      page
      perPage
      totalCount
    }
  }
`;
