import { ApolloError } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { ErrorMessage } from 'components';
import { DEFAULT_PRIMARY_COLOR } from 'utils';

type LoadingProps = {
  loading: boolean;
  technicianError: Error | undefined;
};

export function Loading({ loading, technicianError }: LoadingProps) {
  const theme = useTheme();

  return (
    <Grid container width="100vw" height="100vh">
      <Grid item xs={12} md={6}>
        <Box bgcolor={DEFAULT_PRIMARY_COLOR} height="calc(100% + 2px)">
          <Box
            mt="-2px"
            ml={6}
            mr={6}
            mb={6}
            border={`2px solid ${theme.palette.grey[300]}`}
            display="flex"
            justifyContent="center"
            height={`calc(100% - ${theme.spacing(6)})`}
          >
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              sm={7}
              spacing={1}
            >
              <Grid item>
                <Box
                  height="100%"
                  width="100%"
                  sx={{ '> img': { maxHeight: '100%', maxWidth: '100%' } }}
                >
                  <img
                    src="/static/login-main.svg"
                    alt="technician facilitating remote assist"
                  />
                </Box>
              </Grid>
              <Grid item>
                <Typography variant="h3" color={theme.palette.common.white}>
                  Welcome Back to Field Guide
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  color={theme.palette.common.white}
                  align="left"
                >
                  Your toolkit for Remote Assist
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        direction="column"
        xs={12}
        md={6}
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <>
          {loading ? (
            <Grid item>
              <CircularProgress
                size={80}
                sx={{ color: DEFAULT_PRIMARY_COLOR }}
              />
            </Grid>
          ) : (
            <>
              {technicianError && (
                <Grid item>
                  <Box p={1}>
                    <ErrorMessage
                      message={
                        (technicianError as ApolloError).graphQLErrors?.find(
                          (error) =>
                            ['404', 'FORBIDDEN'].includes(
                              error.extensions?.code,
                            ),
                        )
                          ? 'No technician associated with your account. Please contact your manager and try again.'
                          : technicianError.message
                      }
                    />
                  </Box>
                </Grid>
              )}
            </>
          )}
        </>
      </Grid>
    </Grid>
  );
}
