import { gql } from '@apollo/client';

export const VALIDATE_PHONE_QUERY = gql`
  query validatePhone($number: String!) {
    validatePhone(number: $number) {
      valid
      line_type
    }
  }
`;
