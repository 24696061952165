export const TOAST_VARIANTS = ['error', 'warning', 'info', 'success'] as const;
export type ToastVariant = typeof TOAST_VARIANTS[number];

export type CreateToastMessage = {
  content: string;
  variant?: ToastVariant;
  action?: {
    text: string;
    function: () => void;
  };
  autohide?: number;
  closeable?: boolean;
};

export type ToastMessage = CreateToastMessage & {
  id: number;
  showing: boolean;
  shownTime: number;
};

const TOAST_MESSAGE_LIMIT = 5;

const TOAST_SHOWN_TIME_INTERVAL = 100;

const toastMessages: Map<number, ToastMessage> = new Map();

const onToastMessagesChangeEvents: ((
  displayToastMessages: ToastMessage[],
) => void)[] = [];

let id = 0;

export const getDisplayToastMessages = () => {
  const toDisplay = [...new Set(toastMessages.values())].slice(
    0,
    TOAST_MESSAGE_LIMIT,
  );
  toDisplay.forEach((message) => {
    if (!message.showing) {
      message.showing = true;
      if (message.autohide) {
        const interval = setInterval(() => {
          message.shownTime = message.shownTime + TOAST_SHOWN_TIME_INTERVAL;
          if (!message.autohide || message.shownTime >= message.autohide) {
            clearInterval(interval);
            deleteToastMessage(message.id);
          }
          triggerToastMessageChange();
        }, TOAST_SHOWN_TIME_INTERVAL);
      }
    }
  });
  return toDisplay;
};

const triggerToastMessageChange = () => {
  const displayToastMessages = getDisplayToastMessages();
  onToastMessagesChangeEvents.forEach((event) => event(displayToastMessages));
};

export const onToastMessageChange = (
  event: (displayToastMessages: ToastMessage[]) => void,
) => {
  onToastMessagesChangeEvents.push(event);
};

export const pushToastMessage = (newMessage: CreateToastMessage) => {
  toastMessages.set(id, { id, showing: false, shownTime: 0, ...newMessage });
  id++;
  if (toastMessages.size <= TOAST_MESSAGE_LIMIT) {
    triggerToastMessageChange();
  }
};

export const deleteToastMessage = (id: number) => {
  toastMessages.delete(id);
  triggerToastMessageChange();
};
