import { gql } from '@apollo/client';

export const TECHNICIANS_QUERY = gql`
  query technicians($input: TechniciansInput) {
    technicians(input: $input) {
      id
      name
    }
  }
`;
