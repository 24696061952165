import { useMutation } from '@apollo/client';
import {
  UPDATE_REMOTE_ASSIST_CUSTOMER_MUTATION,
  VERIFY_ATTENDEE_MUTATION,
} from 'api';
import { useRemoteAssistSessionStorage } from 'hooks';
import { clone, isEqual } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  RemoteAssist,
  RemoteAssistCustomerUpdate,
  UpdateRemoteAssistCustomerMutation,
  UpdateRemoteAssistCustomerMutationVariables,
  VerifyAttendeeMutation,
  VerifyAttendeeMutationVariables,
} from 'types';

function trimAll<T extends Record<string, any>>(obj: T): T {
  const trimmedObj = clone(obj);
  Object.entries(trimmedObj).forEach(([key, entry]) => {
    if (typeof entry === 'string') {
      (trimmedObj as Record<string, any>)[key] = entry.trim();
    } else if (entry && typeof entry === 'object') {
      (trimmedObj as Record<string, any>)[key] = trimAll(entry);
    }
  });
  return trimmedObj;
}

const VALIDATION_KEYS = [
  'phone',
  'email',
  'serviceLocation',
  'firstName',
  'lastName',
] as const;
type ValidationKey = typeof VALIDATION_KEYS[number];

const validationKeyToCustomerKey: Record<
  ValidationKey,
  keyof RemoteAssistCustomerUpdate
> = {
  phone: 'phone',
  email: 'email',
  serviceLocation: 'postalCode',
  firstName: 'firstName',
  lastName: 'lastName',
};

type CustomerEditRemoteAssistCustomer = Partial<RemoteAssist['customer']>;

type CustomerEditRemoteAssist = {
  id: string;
  clientId: string;
  supplyClientId: string;
  customer: CustomerEditRemoteAssistCustomer;
};

type UseCustomerEditProps = {
  remoteAssist: CustomerEditRemoteAssist;
  isCurrentSession: boolean;
};

export function useCustomerEdit({
  remoteAssist,
  isCurrentSession,
}: UseCustomerEditProps) {
  const [updateRemoteAssistCustomer, { loading: customerLoading }] =
    useMutation<
      UpdateRemoteAssistCustomerMutation,
      UpdateRemoteAssistCustomerMutationVariables
    >(UPDATE_REMOTE_ASSIST_CUSTOMER_MUTATION);

  const [verifyAttendee, { loading: validationLoading }] = useMutation<
    VerifyAttendeeMutation,
    VerifyAttendeeMutationVariables
  >(VERIFY_ATTENDEE_MUTATION);

  const [errorMessage, setErrorMessage] = useState('');

  const [validationResponse, setValidationResponse] = useState<
    NonNullable<VerifyAttendeeMutation['verifyAttendee']>
  >({ valid: true });

  // the most up-to-date customer info state
  // stores validated input in CustomerInfoSection
  const [customerUpdate, setCustomerUpdate] =
    useState<RemoteAssistCustomerUpdate | null>(null);

  // the last customer update to be validated
  const [validatedCustomerUpdate, setValidatedCustomerUpdate] =
    useState<RemoteAssistCustomerUpdate | null>(null);

  // current customer values - what is currently saved for the customer
  const currentCustomerValues = useMemo(() => {
    return {
      firstName: remoteAssist.customer.firstName ?? null,
      lastName: remoteAssist.customer.lastName ?? null,
      email: remoteAssist.customer.email?.address ?? null,
      phone: remoteAssist.customer.phone?.number ?? null,
      postalCode: remoteAssist.customer.postalCode ?? null,
    };
  }, [remoteAssist]);

  // the only hook that useRemoteAssistSessionStorge uses is useState
  const [customerUpdateInput, setCustomerUpdateInput] = isCurrentSession
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useRemoteAssistSessionStorage<RemoteAssistCustomerUpdate>(
        remoteAssist.id,
        'customerUpdateInput',
        currentCustomerValues,
      )
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useState<RemoteAssistCustomerUpdate>(currentCustomerValues);

  useEffect(() => {
    setCustomerUpdate(trimAll(customerUpdateInput));
  }, [customerUpdateInput, setCustomerUpdate]);

  const onChange = useCallback(
    (variableName: keyof RemoteAssistCustomerUpdate) =>
      (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string) => {
        const value = typeof event === 'string' ? event : event.target.value;
        const newCustomerUpdateInput = {
          ...customerUpdate,
          [variableName]: value.trim() === '' ? null : value,
        };
        setCustomerUpdateInput(newCustomerUpdateInput);
        return newCustomerUpdateInput;
      },
    [customerUpdate, setCustomerUpdateInput],
  );

  const loading = useMemo(
    () => customerLoading || validationLoading,
    [customerLoading, validationLoading],
  );

  const runCustomerValidation = useCallback(async () => {
    const { data } = await verifyAttendee({
      variables: {
        attendee: {
          supplyClientId: remoteAssist.supplyClientId,
          demandClientId: remoteAssist.clientId,
          phone: customerUpdate?.phone,
          email: customerUpdate?.email,
          serviceLocation: customerUpdate?.postalCode,
          firstName: customerUpdate?.firstName,
          lastName: customerUpdate?.lastName,
        },
      },
    });
    const validationResponse = data?.verifyAttendee ?? { valid: true };
    setValidationResponse(validationResponse);
    let currentCustomerUpdate = customerUpdate;
    const updateResponse = (key: ValidationKey) => {
      const newValue = validationResponse?.[key]?.newValue;
      if (newValue) {
        currentCustomerUpdate = onChange(validationKeyToCustomerKey[key])(
          newValue,
        );
      }
    };
    VALIDATION_KEYS.forEach((key) => updateResponse(key));
    setValidatedCustomerUpdate(currentCustomerUpdate);
    return { currentCustomerUpdate, validationResponse };
  }, [
    customerUpdate,
    onChange,
    remoteAssist.clientId,
    remoteAssist.supplyClientId,
    verifyAttendee,
  ]);

  const customerUpdateMatchesCurrent = useMemo(() => {
    const match = isEqual(customerUpdate, currentCustomerValues);
    return match;
  }, [customerUpdate, currentCustomerValues]);

  const updateCustomer = useCallback(async () => {
    try {
      if (customerUpdateMatchesCurrent) {
        return true;
      }
      const { currentCustomerUpdate, validationResponse } =
        await runCustomerValidation();
      if (validationResponse?.valid === false) {
        return false;
      }
      await updateRemoteAssistCustomer({
        variables: {
          id: remoteAssist.id,
          remoteAssistUpdateInput: {
            customer: currentCustomerUpdate,
          },
        },
      });
      setErrorMessage('');
      return true;
    } catch (error: any) {
      setErrorMessage(
        error.graphQLErrors?.[0]?.extensions?.response?.body?.data?.message ??
          'Unable to update customer details',
      );
      return false;
    }
  }, [
    customerUpdateMatchesCurrent,
    remoteAssist.id,
    runCustomerValidation,
    updateRemoteAssistCustomer,
  ]);

  return {
    updateCustomer,
    onChange,
    customerUpdateMatchesCurrent,
    loading,
    errorMessage,
    currentCustomerValues,
    customerUpdateInput,
    validatedCustomerUpdate,
    setCustomerUpdateInput,
    validationResponse,
  };
}
