import { lazy } from 'react';
import { UserRole, View } from 'utils';

export const views: View[] = [
  {
    id: 'settings',
    sidebar: {
      name: 'Settings',
      icon: lazy(() => import('@mui/icons-material/Settings')),
    },
    checker: ({ userRoles }) => {
      return userRoles.includes(UserRole.Admin);
    },
    path: '/settings',
    component: lazy(() => import('./component')),
  },
];
