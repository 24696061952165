import { lazy } from 'react';
import { UserRole, View } from 'utils';

export const views: View[] = [
  {
    id: 'technician-dashboard',
    sidebar: {
      name: 'Video Calls',
      icon: lazy(() => import('./icon')),
    },
    checker: ({ userRoles }) => {
      return userRoles.includes(UserRole.Technician);
    },
    path: '/technician-dashboard',
    component: lazy(() => import('./component')),
  },
];
