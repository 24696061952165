import { TextField, Theme, createTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OutlinedTextFieldProps } from '@mui/material/TextField';

export const DEFAULT_PRIMARY_COLOR = '#1A3F6C';
export const DEFAULT_SECONDARY_COLOR = '#019592';

export const generateTheme = (
  primaryColor = DEFAULT_PRIMARY_COLOR,
  secondaryColor?: string,
): Theme => {
  const mainSecondaryColor =
    primaryColor === DEFAULT_PRIMARY_COLOR
      ? DEFAULT_SECONDARY_COLOR
      : secondaryColor ?? primaryColor;

  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
      info: { main: '#A2B5F5', light: '#C6E0F7' },
      error: { main: '#f44336' },
      warning: { main: '#ffa726' },
      success: { main: '#66bb6a', light: '#B7F791' },
    },
  });
};

interface StyledTextFieldProps extends OutlinedTextFieldProps {
  error?: boolean;
}

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'error',
})<StyledTextFieldProps>(({ error, theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    fieldset: {
      borderColor: error ? 'red' : theme.palette.grey[700],
      borderWidth: '1px',
    },
  },
  '& label.MuiInputLabel-root': {
    color: error ? 'red' : theme.palette.grey[700],
  },
  '& p.MuiFormHelperText-root': {
    color: error ? 'red' : theme.palette.grey[700],
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    fieldset: {
      borderColor: error ? 'red' : theme.palette.grey[700],
      borderWidth: '1px',
    },
  },
  '& .MuiOutlinedInput-root:hover': {
    fieldset: {
      borderColor: error ? 'red' : theme.palette.grey[700],
      borderWidth: '1px',
    },
  },
}));
