import { useEffect, useRef } from 'react';

type Callback = () => void;

// Ref: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: Callback, delay: number) => {
  const savedCallback = useRef<Callback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback?.current && savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
