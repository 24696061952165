import { gql } from '@apollo/client';

export const TECHNICIAN_QUERY = gql`
  query technician {
    technician {
      id
      name
      channelStatuses {
        video
      }
      email {
        address
      }
      status
    }
  }
`;
