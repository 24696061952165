import { Warning } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

type ErrorMessageProps = {
  message: string | JSX.Element;
  variant?: Variant;
  iconSize?: number;
};

export const ErrorMessage = ({
  message,
  variant = 'body2',
  iconSize = 24,
}: ErrorMessageProps) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      width="100%"
      pb={1}
      pr={1}
      spacing={1}
      margin="0"
      bgcolor="rgba(196, 50, 86, 0.16)"
      color="#c43256"
    >
      <Grid item>
        <Warning color="inherit" sx={{ fontSize: iconSize }} />
      </Grid>
      <Grid item>
        <Typography
          variant={variant}
          color="inherit"
          sx={{ wordWrap: 'break-word' }}
        >
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};
