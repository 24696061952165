import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

type VideoModalProps = {
  url: string | string[];
  open?: boolean;
  handleClose?: () => void;
};

export function VideoModal({ url, open, handleClose }: VideoModalProps) {
  const [modalOpen, setModalOpen] = useState(open ?? true);
  const [currentUrlIndex, setCurrentUrlIndex] = useState(0);
  const urls = useMemo(() => (typeof url === 'string' ? [url] : url), [url]);
  const handleModalClose = () => {
    handleClose?.();
    setModalOpen(false);
  };

  useEffect(() => {
    if (open) {
      setModalOpen(open);
    }
  }, [open]);

  return (
    <Dialog open={modalOpen} onClose={handleModalClose} maxWidth={false}>
      <Box p={2}>
        <DialogTitle>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography variant="h4">Recording</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleModalClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item>
              {urls.length === 0 ? (
                <Box p={4}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <Typography variant="body1">
                        Oops... no recording found
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        It can take a while for recordings to load after a
                        session.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: '60vw',
                    bgcolor: 'black',
                    p: 4,
                  }}
                >
                  <video
                    {...{ 'aria-label': 'video' }}
                    style={{
                      maxHeight: '60vh',
                    }}
                    width="100%"
                    controls
                    src={urls[currentUrlIndex]}
                  />
                </Box>
              )}
            </Grid>
            {urls.length > 1 && (
              <Grid item pt={2}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <IconButton
                      size="large"
                      onClick={() => setCurrentUrlIndex(currentUrlIndex - 1)}
                      disabled={currentUrlIndex === 0}
                    >
                      <ArrowBack />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" variant="h6">{`${
                      currentUrlIndex + 1
                    }/${urls.length}`}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="large"
                      onClick={() => setCurrentUrlIndex(currentUrlIndex + 1)}
                      disabled={currentUrlIndex + 1 === urls.length}
                    >
                      <ArrowForward />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
