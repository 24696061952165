import {
  DeveloperApiClient,
  DeveloperApiClientTypes,
} from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type ClientApiContextProps = {
  getClient: DeveloperApiClient['getClient'];
  updateClient: DeveloperApiClient['updateClient'];
};

export type ClientApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: ClientApiContextProps = {
  getClient: () => throwDeveloperApiUnauthenticatedError(),
  updateClient: () => throwDeveloperApiUnauthenticatedError(),
};

export const ClientApiContext =
  createContext<ClientApiContextProps>(DEFAULT_CONTEXT);

export const ClientApiProvider = ({ children }: ClientApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getClient = useCallback(
    async () => await getDeveloperApiClient().getClient(),
    [getDeveloperApiClient],
  );

  const updateClient = useCallback(
    async (input: DeveloperApiClientTypes.UpdateClientInput) =>
      await getDeveloperApiClient().updateClient(input),
    [getDeveloperApiClient],
  );

  return (
    <ClientApiContext.Provider
      value={{
        getClient,
        updateClient,
      }}
    >
      {children}
    </ClientApiContext.Provider>
  );
};
