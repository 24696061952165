import { lazy } from 'react';
import { UserRole, View } from 'utils';

export const views: View[] = [
  {
    checker: ({ userRoles }) => {
      return userRoles.includes(UserRole.Technician);
    },
    id: 'remote-assist-queue-call',
    path: '/remote-assist-call/:remoteAssistId',
    component: lazy(() => import('./component')),
  },
];
