import { gql } from '@apollo/client';

export const CLIENTS_QUERY = gql`
  query clients {
    clients {
      id
      name
      relations
      supply
    }
  }
`;
