import { Typography, useTheme } from '@mui/material';

export const Unknown = () => {
  const theme = useTheme();

  return (
    <Typography
      variant="subtitle2"
      fontWeight={400}
      color={theme.palette.grey[500]}
    >
      n/a
    </Typography>
  );
};
