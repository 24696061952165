import {
  QueueFacadeFactory,
  Ticket,
  TicketFactory,
  queue,
  queueBooleanStrategies,
  queueGetNumberStrategies,
  queueSetStrategies,
  queueStringStrategies,
} from '@hpx-it/queue-client';
import { makeRequest } from '@hpx-it/react-app';
import { UserContext } from 'contexts';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { DeveloperApiClientContext } from '..';

type QueueApiContextProps = {
  getQueue: (filter: QueueFilter) => Promise<Ticket[] | undefined>;
  getPinnedTickets: (id: string) => Promise<Ticket[] | undefined>;
  queueWorkOrder: (workOrderId: string) => Promise<void>;
  getTicketsOnProperty: (ticket: Ticket) => Promise<Ticket[] | undefined>;
  getTimeslots: () => Promise<queue.AvailableTimeslotsResponse>;
  queueWorkOrderLoading: boolean;
};

export type QueueApiProviderProps = {
  children: ReactNode;
};

export type QueueFilter =
  | { name: 'none' | 'claimed' | 'unclaimed' }
  | { name: 'claimed_by'; claimedById: string };

const DEFAULT_CONTEXT: QueueApiContextProps = {
  getQueue: async () => undefined,
  getPinnedTickets: async (id: string) => undefined,
  queueWorkOrder: async () => {},
  getTicketsOnProperty: async () => undefined,
  getTimeslots: async () => ({ timeslots: [], immediate_available: false }),
  queueWorkOrderLoading: false,
};

export const QueueApiContext =
  createContext<QueueApiContextProps>(DEFAULT_CONTEXT);

export const QueueApiProvider = ({ children }: QueueApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);
  const { token } = useContext(UserContext);
  const [queueWorkOrderLoading, setQueueWorkOrderLoading] = useState(false);

  const queueFacade = useMemo(
    () =>
      new QueueFacadeFactory(
        new TicketFactory({
          isStrategies: queueBooleanStrategies,
          getNumberStrategies: queueGetNumberStrategies,
          setStrategies: queueSetStrategies,
          getStringStrategies: queueStringStrategies,
        }),
      ).getQueueFacade(getDeveloperApiClient()),
    [getDeveloperApiClient],
  );

  const getQueue = useCallback(
    async (filter: QueueFilter) => {
      if (!queueFacade) {
        return undefined;
      }
      switch (filter.name) {
        case 'none':
          return await queueFacade.getCurrentQueue();
        case 'unclaimed':
          return await queueFacade.getCurrentUnclaimedQueue();
        case 'claimed':
          return await queueFacade.getCurrentClaimedQueue();
        case 'claimed_by':
          return await queueFacade.getTicketsClaimedBy(filter.claimedById);
      }
    },
    [queueFacade],
  );

  const getPinnedTickets = useCallback(
    async (id: string) => {
      if (!queueFacade) {
        return undefined;
      }

      return await queueFacade.getTicketsClaimedBy(id);
    },
    [queueFacade],
  );

  const getTicketsOnProperty = useCallback(
    async (ticket: Ticket) => {
      if (!queueFacade) {
        return undefined;
      }
      return await queueFacade.getTicketsOnProperty(ticket.getLocation());
    },
    [queueFacade],
  );

  const queueWorkOrder = useCallback(
    async (workOrderId: string) => {
      setQueueWorkOrderLoading(true);
      try {
        await makeRequest<void>(
          'queueWorkOrder',
          `${process.env.REACT_APP_HPA_INTEGRATION_URL}/workorder/${workOrderId}/queue`,
          {
            method: 'POST',
            headers: {
              ...(token && { authorization: `Bearer ${token}` }),
              'content-type': 'application/json',
            },
          },
        );
      } finally {
        setQueueWorkOrderLoading(false);
      }
    },
    [token],
  );

  const getTimeslots = useCallback(async () => {
    return await getDeveloperApiClient().getTimeslots();
  }, [getDeveloperApiClient]);

  return (
    <QueueApiContext.Provider
      value={{
        getQueue,
        getPinnedTickets,
        queueWorkOrder,
        getTicketsOnProperty,
        getTimeslots,
        queueWorkOrderLoading,
      }}
    >
      {children}
    </QueueApiContext.Provider>
  );
};
