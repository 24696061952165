import { gql } from '@apollo/client';

export const COMPLETE_REMOTE_ASSIST_MUTATION = gql`
  mutation completeRemoteAssist(
    $remoteAssistCompleteInput: RemoteAssistCompleteInput!
    $id: ID!
  ) {
    completeRemoteAssist(
      remoteAssistCompleteInput: $remoteAssistCompleteInput
      id: $id
    ) {
      remoteAssist {
        id
        status
      }
    }
  }
`;
