import {
  DeveloperApiClient,
  DeveloperApiClientTypes,
} from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type ProductInfoApiContextProps = {
  getApplianceInfo: DeveloperApiClient['getApplianceInfo'];
};

export type ProductInfoApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: ProductInfoApiContextProps = {
  getApplianceInfo: () => throwDeveloperApiUnauthenticatedError(),
};

export const ProductInfoApiContext =
  createContext<ProductInfoApiContextProps>(DEFAULT_CONTEXT);

export const ProductInfoApiProvider = ({
  children,
}: ProductInfoApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getApplianceInfo = useCallback(
    async (input: DeveloperApiClientTypes.GetApplianceInfoInput) =>
      await getDeveloperApiClient().getApplianceInfo(input),
    [getDeveloperApiClient],
  );

  return (
    <ProductInfoApiContext.Provider value={{ getApplianceInfo }}>
      {children}
    </ProductInfoApiContext.Provider>
  );
};
