import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, IconButton, Typography, useTheme } from '@mui/material';

type EditableEquipmentActionsProps = {
  loadingEquipment: boolean;
  addEquipment: () => void;
  saveEquipment: () => Promise<void>;
  cancelEquipment: () => void;
  disableSave: boolean;
};

export const EditableEquipmentActions = ({
  loadingEquipment,
  addEquipment,
  saveEquipment,
  cancelEquipment,
  disableSave,
}: EditableEquipmentActionsProps) => {
  const theme = useTheme();

  return (
    <>
      <Grid
        sx={{
          marginTop: '1rem',
        }}
        container
        justifyContent="flex-end"
      >
        <IconButton
          sx={{
            color: theme.palette.secondary.main,
            marginRight: '0.75rem',
          }}
          onClick={addEquipment}
          disabled={loadingEquipment}
        >
          <AddIcon />
          <Typography
            variant="subtitle2"
            sx={{ marginTop: '0.1rem' }}
            fontWeight={500}
          >
            Add Equipment
          </Typography>
        </IconButton>
      </Grid>
      <Grid sx={{ marginY: '1rem' }} container justifyContent="flex-end">
        <Button
          sx={{
            height: '2.5rem',
            borderRadius: '1.25rem',
            textTransform: 'none',
            marginRight: '0.75rem',
          }}
          variant="outlined"
          color="secondary"
          onClick={cancelEquipment}
          disabled={loadingEquipment}
        >
          Cancel
        </Button>
        <Button
          sx={{
            height: '2.5rem',
            borderRadius: '1.25rem',
            textTransform: 'none',
            marginRight: '0.75rem',
          }}
          variant="contained"
          color="secondary"
          disabled={disableSave}
          onClick={saveEquipment}
        >
          Save
        </Button>
      </Grid>
    </>
  );
};
