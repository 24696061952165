import {
  DeveloperApiClient,
  DeveloperApiClientTypes,
} from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type AttachmentApiContextProps = {
  getAttachments: DeveloperApiClient['getAttachments'];
  getAttachmentUrls: DeveloperApiClient['getAttachmentUrls'];
  uploadAttachment: DeveloperApiClient['uploadAttachment'];
  downloadAttachment: DeveloperApiClient['downloadAttachment'];
  updateAttachment: DeveloperApiClient['updateAttachment'];
  deleteAttachment: DeveloperApiClient['deleteAttachment'];
};

export type AttachmentApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: AttachmentApiContextProps = {
  getAttachments: () => throwDeveloperApiUnauthenticatedError(),
  getAttachmentUrls: () => throwDeveloperApiUnauthenticatedError(),
  uploadAttachment: () => throwDeveloperApiUnauthenticatedError(),
  downloadAttachment: () => throwDeveloperApiUnauthenticatedError(),
  updateAttachment: () => throwDeveloperApiUnauthenticatedError(),
  deleteAttachment: () => throwDeveloperApiUnauthenticatedError(),
};

export const AttachmentApiContext =
  createContext<AttachmentApiContextProps>(DEFAULT_CONTEXT);

export const AttachmentApiProvider = ({
  children,
}: AttachmentApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getAttachments = useCallback(
    async (input: DeveloperApiClientTypes.GetAttachmentsInput) =>
      await getDeveloperApiClient().getAttachments(input),
    [getDeveloperApiClient],
  );

  const getAttachmentUrls = useCallback(
    async (input: DeveloperApiClientTypes.GetAttachmentUrlsInput) => {
      return await getDeveloperApiClient().getAttachmentUrls(input);
    },
    [getDeveloperApiClient],
  );

  const uploadAttachment = useCallback(
    async (input: DeveloperApiClientTypes.UploadAttachmentInput) =>
      await getDeveloperApiClient().uploadAttachment(input),
    [getDeveloperApiClient],
  );

  const downloadAttachment = useCallback(
    async (input: DeveloperApiClientTypes.GetAttachmentInput) =>
      await getDeveloperApiClient().downloadAttachment(input),
    [getDeveloperApiClient],
  );

  const updateAttachment = useCallback(
    async (input: DeveloperApiClientTypes.UpdateAttachmentInput) =>
      await getDeveloperApiClient().updateAttachment(input),
    [getDeveloperApiClient],
  );

  const deleteAttachment = useCallback(
    async (input: DeveloperApiClientTypes.DeleteAttachmentInput) =>
      await getDeveloperApiClient().deleteAttachment(input),
    [getDeveloperApiClient],
  );

  return (
    <AttachmentApiContext.Provider
      value={{
        getAttachments,
        getAttachmentUrls,
        uploadAttachment,
        downloadAttachment,
        updateAttachment,
        deleteAttachment,
      }}
    >
      {children}
    </AttachmentApiContext.Provider>
  );
};
