import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSearchParams } from 'react-router-dom';

// takes a value from the URL and automatically updates on any change
export function useURLVariable<T>(
  variableName: string,
  defaultValue: T,
  castToString: (value: T) => string | null = (value) =>
    value as unknown as string | null,
  castFromString: (value: string | null) => T = (value) =>
    value as unknown as T,
): [T, Dispatch<SetStateAction<T>>] {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentValue = useMemo(() => {
    return searchParams.get(variableName);
  }, [searchParams, variableName]);

  const setVariable = useCallback(
    (value: T | ((old: T) => T)) => {
      const newValue = castToString(
        value instanceof Function ? value(castFromString(currentValue)) : value,
      );
      setSearchParams((searchParams) => {
        if (newValue === null) {
          searchParams.delete(variableName);
        } else {
          searchParams.set(variableName, newValue);
        }
        return searchParams;
      });
    },
    [castFromString, castToString, currentValue, setSearchParams, variableName],
  );

  useEffect(() => {
    if (!currentValue) {
      setVariable(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [castFromString(currentValue), setVariable];
}
