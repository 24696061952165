import 'utils/logging';
import 'reflect-metadata';

import { FronteggProvider, FronteggThemeOptions } from '@frontegg/react';
import {
  DevicesProvider,
  MakeRequestProvider,
  TechnicianProvider,
  UserProvider,
} from 'contexts';
import { FieldguideRoutes } from 'pages';
import { BrowserRouter } from 'react-router-dom';

function App() {
  const FrontEggAuthOptions = {
    keepSessionAlive: true,
  };

  const FrontEggContextOptions = {
    baseUrl: process.env.REACT_APP_FRONTEGG_BASEURL || '',
    clientId: process.env.REACT_APP_FRONTEGG_CLIENT_ID || '',
    tenantResolver: () => {
      const urlQueryParams = new URLSearchParams(window.location.search);
      const organization = urlQueryParams.get('organization');
      return {
        tenant: organization,
      };
    },
  };

  const FrontEggThemeOptions: FronteggThemeOptions = {
    adminPortal: {
      layout: {
        fullScreenMode: true,
      },
      pages: {
        header: {
          display: 'none',
        },
      },
    },
  };

  return (
    <BrowserRouter>
      <FronteggProvider
        authOptions={FrontEggAuthOptions}
        contextOptions={FrontEggContextOptions}
        themeOptions={FrontEggThemeOptions}
        entitlementsOptions={{ enabled: true }}
        hostedLoginBox
      >
        <UserProvider>
          <MakeRequestProvider
            baseAPIPath={process.env.REACT_APP_DEVELOPER_API_URL}
          >
            <DevicesProvider>
              <TechnicianProvider>
                <FieldguideRoutes />
              </TechnicianProvider>
            </DevicesProvider>
          </MakeRequestProvider>
        </UserProvider>
      </FronteggProvider>
    </BrowserRouter>
  );
}

export default App;
