import gql from 'graphql-tag';

export const childrenRemoteAssistProps = gql`
  fragment childrenRemoteAssistProps on PagedRemoteAssist {
    id
    parentRelation
    createdAt
    assignedAt
    status
    canceledReason
    scheduled {
      promisedWindow {
        start
      }
    }
  }
`;
