import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton, Typography } from '@mui/material';
import { EditableTextField } from 'components';
import { ChangeEvent } from 'react';
import { Equipment } from 'types';
import { BORDER } from 'utils';

type EquipmentField = {
  label: string;
  value: keyof Equipment;
};

const equipmentFields: EquipmentField[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Type',
    value: 'type',
  },
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Model #',
    value: 'modelNumber',
  },
  {
    label: 'Serial #',
    value: 'serialNumber',
  },
  {
    label: 'Error Code',
    value: 'errorCode',
  },
  {
    label: 'Equipment Notes',
    value: 'equipmentNotes',
  },
];

type EditableEquipmentFieldsProps = {
  equip: Equipment;
  index: number;
  onEquipmentChange: (
    variableName: keyof Equipment,
    index: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  loadingEquipment: boolean;
  deleteEquipment: (index: number) => void;
};

export const EditableEquipmentFields = ({
  equip,
  index,
  onEquipmentChange,
  loadingEquipment,
  deleteEquipment,
}: EditableEquipmentFieldsProps) => {
  return (
    <Grid
      item
      spacing={2}
      bgcolor={(theme) => theme.palette.grey[200]}
      borderRadius="0.5rem"
      border={BORDER}
      sx={{
        margin: '0.5rem 0.5rem',
      }}
    >
      {equipmentFields.map((field) => {
        return (
          <EditableTextField
            id={field.value}
            label={field.label}
            value={equip[field.value] ?? ''}
            index={index}
            onEquipmentChange={onEquipmentChange}
            loadingEquipment={loadingEquipment}
          />
        );
      })}

      <Grid
        sx={{ margin: '1rem 1rem 0.5rem 0rem' }}
        container
        justifyContent="flex-end"
      >
        <IconButton
          sx={{ color: (theme) => theme.palette.secondary.main }}
          onClick={() => deleteEquipment(index)}
          disabled={loadingEquipment}
        >
          <DeleteIcon />
          <Typography
            variant="subtitle2"
            sx={{ marginTop: '0.2rem' }}
            fontWeight={500}
          >
            Delete Equipment
          </Typography>
        </IconButton>
      </Grid>
    </Grid>
  );
};
