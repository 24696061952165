import {
  DeveloperApiClient,
  DeveloperApiClientTypes,
} from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type TradeApiContextProps = {
  getTrades: DeveloperApiClient['getTrades'];
};

export type TradeApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: TradeApiContextProps = {
  getTrades: () => throwDeveloperApiUnauthenticatedError(),
};

export const TradeApiContext =
  createContext<TradeApiContextProps>(DEFAULT_CONTEXT);

export const TradeApiProvider = ({ children }: TradeApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getTrades = useCallback(
    async (input: DeveloperApiClientTypes.GetTrades) =>
      await getDeveloperApiClient().getTrades(input),
    [getDeveloperApiClient],
  );

  return (
    <TradeApiContext.Provider value={{ getTrades }}>
      {children}
    </TradeApiContext.Provider>
  );
};
