import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { Equipment } from 'types';

type CreateEquipmentContextProps = {
  equipmentToCreate: Equipment | undefined;
  setEquipmentToCreate: Dispatch<SetStateAction<Equipment | undefined>>;
};

type CreateEquipmentProviderProps = {
  children: React.ReactNode;
};

const DEFAULT_CONTEXT: CreateEquipmentContextProps = {
  equipmentToCreate: undefined,
  setEquipmentToCreate: () => {},
};

export const CreateEquipmentContext =
  createContext<CreateEquipmentContextProps>(DEFAULT_CONTEXT);

export const CreateEquipmentProvider = ({
  children,
}: CreateEquipmentProviderProps) => {
  const [equipmentToCreate, setEquipmentToCreate] = useState<Equipment>();

  return (
    <CreateEquipmentContext.Provider
      value={{
        equipmentToCreate,
        setEquipmentToCreate,
      }}
    >
      {children}
    </CreateEquipmentContext.Provider>
  );
};
