import { gql } from '@apollo/client';

export const TECHNICIANS_FOR_TEAM_QUERY = gql`
  query techniciansForTeam {
    technicians(input: { userClientOnly: true }) {
      id
      name
      channelStatuses {
        video
      }
    }
  }
`;
