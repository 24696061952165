import { Chip, Grid, Typography } from '@mui/material';
import { CLIENT_ID_TO_CLIENT_MAP } from 'utils';

type ClientChipProps = {
  clientId: string;
};

export const ClientChip = ({ clientId }: ClientChipProps) => {
  return (
    <Chip
      label={
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="body2" color="textSecondary" fontWeight={500}>
              {CLIENT_ID_TO_CLIENT_MAP[clientId].name}
            </Typography>
          </Grid>
        </Grid>
      }
      sx={{ borderRadius: '0.5rem' }}
    />
  );
};
