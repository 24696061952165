import { gql } from '@apollo/client';

export const VERIFY_ATTENDEE_MUTATION = gql`
  mutation verifyAttendee($attendee: Attendee!) {
    verifyAttendee(attendee: $attendee) {
      valid
      phone {
        newValue
        error
        warning
      }
      email {
        newValue
        error
        warning
      }
      serviceLocation {
        newValue
        error
        warning
      }
      firstName {
        newValue
        error
        warning
      }
      lastName {
        newValue
        error
        warning
      }
    }
  }
`;
