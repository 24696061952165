import { DeveloperApiClient } from '@hpx-it/developer-api-client';
import { trades } from '@hpx-it/developer-api-client/serviceTypes';
import { logger } from '@hpx-it/react-app';
import { CLIENTS_QUERY, TECHNICIANS_QUERY } from 'api';
import { client } from 'api';
import { SERVICE_CODES_QUERY } from 'api/queries/serviceCodes';
import {
  Client,
  ClientRelation,
  ClientsQuery,
  RemoteAssistStatus,
  ServiceCode,
  Technician,
  TechnicianStatus,
  VideoStatus,
} from 'types';

import { generateTheme } from './theme';

const { REACT_APP_HPA_CLIENT_ID } = process.env;

export let TRADE_ID_TO_NAME_MAP: Record<string, string> = {};

const theme = generateTheme();

export let SERVICE_CODE_ID_TO_NAME_MAP: Record<string, string> = {};

export let CLIENT_ID_TO_NAME_MAP: Record<string, string> = {};
export let CLIENT_ID_TO_CLIENT_MAP: Record<
  string,
  ClientsQuery['clients'][number]
> = {};
export const getClients = (relations?: ClientRelation[]) =>
  Object.values(CLIENT_ID_TO_CLIENT_MAP).filter(
    (client) =>
      !relations ||
      client.relations.some((relation) => relations.includes(relation)),
  );
export const getCurrentClient = () =>
  getClients([ClientRelation.CURRENT_CLIENT])[0];
export const getSupplyClients = () =>
  getClients([ClientRelation.CURRENT_CLIENT_SUPPLY]);
export const hasMultipleSupplyClients = () => getSupplyClients().length > 1;
export const hasHPASupply = (clientId: string) =>
  CLIENT_ID_TO_CLIENT_MAP[clientId]?.supply.includes(REACT_APP_HPA_CLIENT_ID) ??
  false;
export const isHPA = (clientId: string) => clientId === REACT_APP_HPA_CLIENT_ID;
export const getDemandClients = () =>
  getClients([ClientRelation.CURRENT_CLIENT_DEMAND]);
export const hasMultipleDemandClients = () => getDemandClients().length > 1;
export const isClientOrHasDemandClient = (clientId: string) =>
  getClients([
    ClientRelation.CURRENT_CLIENT,
    ClientRelation.CURRENT_CLIENT_DEMAND,
  ]).some((client) => client.id === clientId);

export let TECHNICIAN_ID_TO_NAME_MAP: Record<string, string> = {};

export const RAS_PER_PAGE: number = 5;
export const TECHNICIAN_VIDEO_STATUS_NAME_MAP: Record<string, string> = {
  AVAILABLE: 'Available',
  ERROR: 'Error',
  ON_CALL: 'On Call',
  TASK_PENDING: 'Task Pending',
  UNAVAILABLE: 'Unavailable',
};

export const TECHNICIAN_VIDEO_STATUS_RANKING: Record<string, number> = {
  AVAILABLE: 1,
  TASK_PENDING: 2,
  ON_CALL: 3,
  UNAVAILABLE: 4,
  ERROR: 5,
};

export const PORTAL_STATUS_TO_DISPLAY_NAME_MAP: Record<string, string> = {
  IN_PROGRESS: 'In Progress',
  CANCELLED: 'Canceled',
  RESOLVED: 'Resolved',
  UNRESOLVED: 'Unresolved',
  RESCHEDULED: 'Rescheduled',
};
export const PORTAL_STATUS_TO_COLOR_MAP: Record<string, string> = {
  IN_PROGRESS: '#FFF5E1',
  CANCELLED: '#FFEDE9',
  RESOLVED: '#E5FAF8',
  UNRESOLVED: '#E3F1FD',
  RESCHEDULED: `${theme.palette.grey[200]}`,
};

export const PORTAL_STATUS_TO_ICON_MAP: Record<string, string> = {
  IN_PROGRESS: '/static/status-icons/in-progress.svg',
  CANCELLED: '/static/status-icons/cancelled.svg',
  RESOLVED: '/static/status-icons/resolved.svg',
  UNRESOLVED: '/static/status-icons/unresolved.svg',
  RESCHEDULED: '/static/status-icons/rescheduled.svg',
};

export const PORTAL_ORDER_TO_RANGE_MAP = {
  ASSIGNED_AT: 'assignedAtRange',
  SCHEDULED_START_AT: 'scheduledStartAtRange',
  CREATED_AT: 'lastStatusChangeAtRange', // TODO: change to createdAtRange once API is updated or remove CREATED_AT from API
  LAST_STATUS_CHANGE_AT: 'lastStatusChangeAtRange',
};

export async function init(
  getTrades: DeveloperApiClient['getTrades'],
): Promise<void> {
  try {
    await Promise.all([
      (async () => {
        const clients: Client[] =
          (await client.query({ query: CLIENTS_QUERY })).data?.clients ?? [];
        CLIENT_ID_TO_NAME_MAP = {};
        CLIENT_ID_TO_CLIENT_MAP = {};
        for (const client of clients) {
          CLIENT_ID_TO_NAME_MAP[client.id] = client.name;
          CLIENT_ID_TO_CLIENT_MAP[client.id] = client;
        }
      })(),
      (async () => {
        const trades: trades.Trades.Trade[] = (await getTrades({})) ?? [];
        TRADE_ID_TO_NAME_MAP = {};
        for (const trade of trades) {
          TRADE_ID_TO_NAME_MAP[trade.id] = trade.name;
        }
      })(),
      (async () => {
        const serviceCodes: ServiceCode[] =
          (await client.query({ query: SERVICE_CODES_QUERY })).data
            ?.serviceCodes.data ?? [];
        SERVICE_CODE_ID_TO_NAME_MAP = {};
        for (const serviceCode of serviceCodes) {
          SERVICE_CODE_ID_TO_NAME_MAP[serviceCode.id] = serviceCode.name;
        }
      })(),
      (async () => {
        const technicians: Technician[] =
          (
            await client.query({
              query: TECHNICIANS_QUERY,
              variables: { input: { showArchived: true } },
            })
          ).data?.technicians ?? [];
        TECHNICIAN_ID_TO_NAME_MAP = {};
        for (const tech of technicians) {
          TECHNICIAN_ID_TO_NAME_MAP[tech.id] = tech.name;
        }
      })(),
    ]);
  } catch (error) {
    logger.error(
      'Received error while mapping clients, trades, service codes, and technicians',
      { message: error },
    );
  }
}

export const STATUS_TYPES = [
  TechnicianStatus.AVAILABLE,
  TechnicianStatus.UNAVAILABLE,
  TechnicianStatus.OFFLINE,
] as const;
export const technicianStatusColor = (status: TechnicianStatus) => {
  switch (status) {
    case TechnicianStatus.AVAILABLE:
      return 'lawngreen';
    case TechnicianStatus.UNAVAILABLE:
      return 'orangered';
    case TechnicianStatus.OFFLINE:
      return 'lightgrey';
  }
};

export const technicianVideoStatusColor = (videoStatus: VideoStatus) => {
  switch (videoStatus) {
    case VideoStatus.AVAILABLE:
      return '#19BD3A';
    case VideoStatus.ON_CALL:
      return '#F16E00';
    case VideoStatus.TASK_PENDING:
      return '#F16E00';
    case VideoStatus.ERROR:
      return theme.palette.grey[500];
    case VideoStatus.UNAVAILABLE:
      return theme.palette.grey[500];
  }
};

export const remoteAssistStatusColor = (status: RemoteAssistStatus) => {
  switch (status) {
    case RemoteAssistStatus.COMPLETED:
      return '#21998d';
    default:
      return '#C43256';
  }
};

export const mapAffiliateKeyToDisplayName = (affiliateKey: string) => {
  switch (affiliateKey) {
    case 'fpl':
      return 'FPL';
    default:
      logger.error(
        `Display name for affiliate key '${affiliateKey}' not found.`,
      );
      return affiliateKey;
  }
};

export const TOP_NAV_HEIGHT = '5rem';
export const LEFT_NAV_WIDTH = '4.5rem';
export const LEFT_NAV_WIDTH_EXPANDED = '16.25rem';

export const ICON_COLOR = theme.palette.grey[500];
export const BORDER = `1px solid ${theme.palette.grey[300]}`;

export const ALL_CLIENTS_FILTER_OPTION = 'All Clients';
