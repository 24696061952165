import { Maybe } from 'types';

export const formatAmount = (
  amount?: number | null,
  currency?: string,
  onlyAmount?: boolean,
): string => {
  if (typeof amount !== 'number') {
    return '';
  }

  const resAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ?? 'usd',
  }).format(amount);

  return onlyAmount ? resAmount : `${resAmount} ${currency}`;
};

export const formatTimeRange = (
  startDate: string | Date,
  endDate: string | Date,
) => {
  return `${new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: '2-digit',
    hourCycle: 'h12',
  })
    .format(new Date(startDate))
    .replace(' ', '')} - ${new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: '2-digit',
    hourCycle: 'h12',
  })
    .format(new Date(endDate))
    .replace(' ', '')} (${
    new Intl.DateTimeFormat(undefined, {
      timeZoneName: 'short',
    })
      .formatToParts(new Date(endDate))
      .find((p) => p.type === 'timeZoneName')?.value
  })`;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.startsWith('(')
    ? phoneNumber
    : `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6,
      )}-${phoneNumber.slice(6)}`;
};

export const formatRaAppointmentTime = (startTime: string) => {
  return `${new Intl.DateTimeFormat(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(new Date(startTime))} (${
    new Intl.DateTimeFormat(undefined, {
      timeZoneName: 'short',
    })
      .formatToParts(new Date(startTime))
      .find((p) => p.type === 'timeZoneName')?.value
  })`;
};

export const formatBillableVideoMinutes = (
  billableVideoMinutes: Maybe<number> | undefined,
) => {
  if (typeof billableVideoMinutes !== 'number') {
    return undefined;
  }

  return `${billableVideoMinutes} ${
    billableVideoMinutes === 1 ? 'minute' : 'minutes'
  }`;
};

export const upperCaseFirstLetter = (string: string) =>
  `${string.slice(0, 1).toUpperCase()}${string.slice(1).toLowerCase()}`;
