import moment from 'moment';

export const calculateDuration = (
  startTime: string | Date,
  endTime: string | Date,
) => {
  const startDate = moment(startTime);
  const endDate = moment(endTime);
  const diff = endDate.diff(startDate);
  const diffDuration = moment.duration(diff);
  const finalDuration = `
		  ${diffDuration.hours().toLocaleString(undefined, {
        minimumIntegerDigits: 2,
      })}:${diffDuration.minutes().toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${diffDuration.seconds().toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}`;
  return finalDuration;
};
