import { useQuery } from '@apollo/client';
import { REMOTE_ASSISTS_QUERY, TECHNICIAN_QUERY } from 'api';
import { createContext, useContext, useEffect, useMemo } from 'react';
import {
  RemoteAssistStatus,
  RemoteAssistsQuery,
  RemoteAssistsQueryVariables,
  TechnicianQuery,
  TechnicianQueryVariables,
  VideoStatus,
} from 'types';
import { UserRole } from 'utils';

import { UserContext } from './UserContext';

type TechnicianContextProps = {
  technician?: TechnicianQuery['technician'];
  currentRemoteAssist?: NonNullable<
    RemoteAssistsQuery['remoteAssists']
  >['data'][number];
  loading: boolean;
  error?: Error;
};

type TechnicianProviderProps = {
  children: React.ReactNode;
};

const DEFAULT_CONTEXT: TechnicianContextProps = { loading: false };

export const TechnicianContext =
  createContext<TechnicianContextProps>(DEFAULT_CONTEXT);

export const TechnicianProvider = ({ children }: TechnicianProviderProps) => {
  const { isUserAuthenticated, userRoles, userLoading } =
    useContext(UserContext);

  const {
    data: { technician } = {},
    loading: technicianLoading,
    error: technicianError,
  } = useQuery<TechnicianQuery, TechnicianQueryVariables>(TECHNICIAN_QUERY, {
    pollInterval: 30000,
    skip: !isUserAuthenticated || !userRoles.includes(UserRole.Technician),
  });

  const { data: { remoteAssists: currentRemoteAssists = null } = {}, refetch } =
    useQuery<RemoteAssistsQuery, RemoteAssistsQueryVariables>(
      REMOTE_ASSISTS_QUERY,
      {
        skip: !technician?.id,
        variables: {
          input: {
            technicianId: technician?.id,
            status: [RemoteAssistStatus.ASSIGNED],
          },
        },
      },
    );

  const currentRemoteAssist = useMemo(
    () => currentRemoteAssists?.data?.[0] ?? undefined,
    [currentRemoteAssists],
  );

  useEffect(() => {
    if (
      technician?.channelStatuses.video === VideoStatus.ON_CALL &&
      !currentRemoteAssist
    ) {
      refetch();
    }
  }, [currentRemoteAssist, refetch, technician?.channelStatuses.video]);

  return (
    <TechnicianContext.Provider
      value={{
        technician,
        currentRemoteAssist,
        loading: userLoading || technicianLoading,
        error: technicianError,
      }}
    >
      {children}
    </TechnicianContext.Provider>
  );
};
