import { gql } from '@apollo/client';

export const UPDATE_TECHNICIAN_STATUS_MUTATION = gql`
  mutation updateTechnicianStatus($status: TechnicianStatus!) {
    updateTechnicianStatus(status: $status) {
      technician {
        id
        status
      }
    }
  }
`;
