import { lazy } from 'react';
import { View } from 'utils';

export const views: View[] = [
  {
    id: 'booking',
    path: '/booking',
    component: lazy(() => import('./component')),
    showNav: false,
  },
];
