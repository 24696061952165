import { gql } from '@apollo/client';

export const UPDATE_REMOTE_ASSIST_CUSTOMER_MUTATION = gql`
  mutation updateRemoteAssistCustomer(
    $id: ID!
    $remoteAssistUpdateInput: RemoteAssistUpdateInput!
  ) {
    updateRemoteAssist(
      id: $id
      remoteAssistUpdateInput: $remoteAssistUpdateInput
    ) {
      success
      remoteAssist {
        id
        customer {
          email {
            address
          }
          firstName
          lastName
          phone {
            number
          }
          postalCode
          notes
        }
      }
    }
  }
`;
