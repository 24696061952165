import { Grid, TextField, useTheme } from '@mui/material';
import { ChangeEvent } from 'react';
import { Equipment } from 'types';

type EditableTextFieldProps = {
  id: keyof Equipment;
  label: string;
  value: string;
  index: number;
  onEquipmentChange: (
    variableName: keyof Equipment,
    index: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  loadingEquipment: boolean;
};
export const EditableTextField = ({
  id,
  label,
  value,
  index,
  onEquipmentChange,
  loadingEquipment,
}: EditableTextFieldProps) => {
  const theme = useTheme();

  return (
    <Grid container xs={12}>
      <TextField
        id={id}
        label={label}
        value={value ?? ''}
        onChange={onEquipmentChange(id, index)}
        disabled={loadingEquipment}
        size="small"
        sx={{
          background: theme.palette.common.white,
          margin: '0.5rem 0.5rem',
        }}
        fullWidth
      />
    </Grid>
  );
};
