import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { logger } from '@hpx-it/react-app';
import { RestLink } from 'apollo-link-rest';
import { getToken } from 'utils';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_FIELDGUIDE_API_URL}/graphql` || '',
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// this handles all errors in calling the api
const errLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      logger.error('Received GraphQL error from GraphQL client', {
        message: graphQLError,
      });
    });
  }
  if (networkError) {
    logger.error('Received network error from GraphQL client', {
      message: networkError,
    });
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Number.POSITIVE_INFINITY,
    jitter: true,
  },
  attempts: {
    max: 3,
  },
});

const restLink = new RestLink({
  uri: `${process.env.REACT_APP_FIELDGUIDE_API_URL}/v1`,
});

// Reference: https://www.apollographql.com/docs/react/api/link/apollo-link-rest/#link-order
const link = ApolloLink.from([
  authLink,
  restLink,
  errLink,
  retryLink,
  httpLink,
]);

export const client = new ApolloClient({
  link,
  // TODO: customize caching if needed
  cache: new InMemoryCache(),
});
