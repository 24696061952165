import { Typography, useTheme } from '@mui/material';

type LabelProps = {
  label: string;
  percentageWidth?: number;
};

export const Label = ({ label, percentageWidth: width = 100 }: LabelProps) => {
  const theme = useTheme();

  return (
    <Typography
      variant="subtitle2"
      fontWeight={500}
      color={theme.palette.grey[500]}
      fontSize="0.75rem"
      width={`${width}%`}
    >
      {label}
    </Typography>
  );
};
