import {
  MakeRequestResponse,
  RequestOptions,
  makeRequest as defaultMakeRequest,
} from '@hpx-it/react-app';
import { UserContext } from 'contexts';
import { ReactNode, createContext, useCallback, useContext } from 'react';

export type MakeRequestContextProps = {
  makeRequest: typeof defaultMakeRequest;
};

type MakeRequestProviderProps = {
  children: ReactNode;
  baseAPIPath?: string;
};

const DEFAULT_CONTEXT: MakeRequestContextProps = {
  makeRequest: async () => ({
    error: { code: 0, message: 'make request context not found' },
  }),
};

export const MakeRequestContext =
  createContext<MakeRequestContextProps>(DEFAULT_CONTEXT);

export const MakeRequestProvider = ({
  children,
  baseAPIPath,
}: MakeRequestProviderProps) => {
  const { token } = useContext(UserContext);
  const makeRequest = useCallback(
    async function makeRequest<T>(
      name: string,
      path: string,
      context?: RequestOptions,
    ): Promise<MakeRequestResponse<T>> {
      return await defaultMakeRequest<T>(name, `${baseAPIPath}${path}`, {
        ...context,
        headers: {
          ...context?.headers,
          ...(token && { authorization: `Bearer ${token}` }),
        },
      });
    },
    [baseAPIPath, token],
  );

  return (
    <MakeRequestContext.Provider value={{ makeRequest }}>
      {children}
    </MakeRequestContext.Provider>
  );
};
