import { gql } from '@apollo/client';

export const REMOTE_ASSIST_QUERY = gql`
  query remoteAssist($id: ID!) {
    remoteAssist(id: $id) {
      id
      reference
      affiliateKey
      demandClientId
      clientId
      supplyClientId
      clientInfo {
        heading
        body
        order
      }
      tradeId
      serviceCodeId
      title
      type
      status
      diagnosis {
        id
        equipment {
          name
          type
          brand
          reference
          modelNumber
          serialNumber
          errorCode
          equipmentNotes
        }
        createdAt
        updatedAt
      }
      customer {
        reference
        email {
          address
        }
        firstName
        lastName
        phone {
          number
        }
        postalCode
        notes
        isMember
      }
      scheduled {
        promisedWindow {
          start
          end
        }
      }
      invoice {
        productType
        productOutcome
        customerPrice
      }
      eligibleSupply {
        technicianIds
      }
      lastStatusChangeAt
      createdAt
      portalStatus
      assignedAt
      parent {
        id
      }
    }
  }
`;
