import gql from 'graphql-tag';

export const parentRemoteAssistProps = gql`
  fragment parentRemoteAssistProps on RemoteAssist {
    id
    parentRelation
    createdAt
    assignedAt
    status
    canceledReason
    scheduled {
      promisedWindow {
        start
      }
    }
  }
`;
